// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
    _18: ImageType,
    _19: ImageType,
    _20: ImageType,
  },
};

class Blog_2016_09_25_Dry_Canyon_Fall_Colors extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          Autumn colors were in full force high up in the canyons below
          Timpanogos.
        </p>
        <p>
          Combined with the recent snow that had fallen the previous few days, I
          knew it would be an amazing sight that I couldn’t pass up.
        </p>
        <Image caption="View up canyon from the trailhead" image={data._1} />
        <Image image={data._2} />
        <Image
          caption="~240' cliff. This is the final gate on the south side of the canyon."
          image={data._3}
        />
        <Image
          caption="Same cliff – I rappelled this last year with a 200′ and 120′ rope, passing the knot mid rappel"
          image={data._4}
        />
        <Image caption="Practicing HDR" image={data._5} />
        <Image caption="Panorama within the trees" image={data._6} />
        <Image
          caption="Past the gates, there is a field with a large fire pit"
          image={data._7}
        />
        <Image
          caption="Higher up, Utah Valley is now visible"
          image={data._8}
        />
        <Image
          caption="Looking up canyon again. Probably about the half way point"
          image={data._9}
        />
        <Image
          caption="A small stream emerges the higher up I climb"
          image={data._10}
        />
        <Image
          caption="The leaves are getting brighter and brighter"
          image={data._11}
        />
        <Image
          caption="Snow! I was not adequately equipped to hike in snow so I knew my turn around point was soon. Just need to get to that one clearing."
          image={data._12}
        />
        <Image
          caption="Beautiful! I could have stayed here for hours. I love that the bright red and white contrast each other so well."
          image={data._13}
        />
        <Image
          caption="Little Baldy saddle in the foreground and Cascade Mountain in the background"
          image={data._14}
        />
        <Image image={data._15} />
        <Image image={data._16} />
        <Image caption="Source of the stream on the trail" image={data._17} />
        <Image
          caption="Broken tree on the trail – broken from the most recent wind storm?"
          image={data._18}
        />
        <Image
          caption="Yet another panorama at the highest gate"
          image={data._19}
        />
        <Image caption="Trailhead a couple of hours later" image={data._20} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2016-09-25-dry-canyon-fall-colors')(
  Blog_2016_09_25_Dry_Canyon_Fall_Colors
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/8.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/10.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/11.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/12.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/13.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/14.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/15.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/16.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/17.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _18: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/18.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _19: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/19.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _20: file(
      relativePath: {
        eq: "images/blog/2016-09-25-dry-canyon-fall-colors/20.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
